import { useNavigate } from "react-router-dom";
import "./App.css";
import AppRouter from "./routes/AppRouter";
import { useEffect, useState } from "react";
import { getDomainName } from "./config/apiConfig";
import { useDispatch } from "react-redux";
import { setDomainName } from "./redux/domainSlice";

function App() {
  const location = window.location;
  const dispatch = useDispatch();

  if (location.hostname !== "localhost") {
    console.log = () => {};
    console.info = () => {};
    console.error = () => {};
    console.warn = () => {};
    console.assert = () => {};
    console.table = () => {};
    console.debug = () => {};
  }

  const [routes, setRoutes] = useState([]);
  const port = window.location.port;

  // useEffect(() => {
  //   const loadRoutes = async () => {
  //     if (port === "3000") {
  //       const blueCollarRoutes = (await import("./routes/blueCollarRoutes")).default;
  //       setRoutes(blueCollarRoutes);
  //     } else if (port === "3001") {
  //       const whiteCollarRoutes = (await import("./routes/whiteCollarRoutes")).default;
  //       setRoutes(whiteCollarRoutes);
  //     }
  //   };
  //   loadRoutes();
  // }, [port]);

  const DomainName = location.hostname;

  useEffect(() => {

    const setDomain = async () => {
      // Determine the environment
      const environment = DomainName.includes(".dev.")
        ? "dev"
        : DomainName.includes(".stg.")
        ? "stg"
        : DomainName === "localhost"
        ? "localhost"
        : "prod"; // Default to "prod" if no .dev., .stg., or localhost is present

      // Determine the subdomain
      let subdomain = "";
      if (
        DomainName.includes("bluecollar") ||
        (environment === "localhost" && port === "3000")
      ) {
        subdomain = "bluecollar";
      } else if (
        DomainName.includes("whitecollar") ||
        (environment === "localhost" && port === "3003")
      ) {
        subdomain = "whitecollar";
      } else if (
        DomainName.includes("hospitality") ||
        (environment === "localhost" && port === "3002")
      ) {
        subdomain = "hospitality";
      } else if (
        DomainName.includes("aijobs") ||
        (environment === "localhost" && port === "3001")
      ) {
        subdomain = "aijobs";
      }

      // Handle routes and dispatch based on environment and subdomain
      switch (subdomain) {
        case "bluecollar":
          switch (environment) {
            case "dev":
            case "localhost":
              dispatch(setDomainName(DomainName));
              setRoutes((await import("./routes/blueCollarRoutes")).default);
              break;
            case "stg":
              dispatch(setDomainName(DomainName));
              setRoutes((await import("./routes/blueCollarRoutes")).default);
              break;
            case "prod":
              dispatch(setDomainName(DomainName));
              setRoutes((await import("./routes/blueCollarRoutes")).default);
              break;
            default:
              console.warn("Environment not recognized for bluecollar.");
          }
          break;

        case "whitecollar":
          switch (environment) {
            case "dev":
            case "localhost":
              dispatch(setDomainName(DomainName));
              setRoutes((await import("./routes/whiteCollarRoutes")).default);
              break;
            case "stg":
              dispatch(setDomainName(DomainName));
              setRoutes((await import("./routes/whiteCollarRoutes")).default);
              break;
            case "prod":
              dispatch(setDomainName(DomainName));
              setRoutes((await import("./routes/whiteCollarRoutes")).default);
              break;
            default:
              console.warn("Environment not recognized for whitecollar.");
          }
          break;

        case "hospitality":
          switch (environment) {
            case "dev":
            case "localhost":
              dispatch(setDomainName(DomainName));
              setRoutes((await import("./routes/hospitalityRoutes")).default);
              break;
            case "stg":
              dispatch(setDomainName(DomainName));
              setRoutes((await import("./routes/hospitalityRoutes")).default);
              break;
            case "prod":
              dispatch(setDomainName(DomainName));
              setRoutes((await import("./routes/hospitalityRoutes")).default);
              break;
            default:
              console.warn("Environment not recognized for hospitality.");
          }
          break;

        case "aijobs":
          switch (environment) {
            case "dev":
            case "localhost":
              dispatch(setDomainName(DomainName));
              setRoutes((await import("./routes/aijobsRoutes")).default);
              break;
            case "stg":
              dispatch(setDomainName(DomainName));
              setRoutes((await import("./routes/aijobsRoutes")).default);
              break;
            case "prod":
              dispatch(setDomainName(DomainName));
              setRoutes((await import("./routes/aijobsRoutes")).default);
              break;
            default:
              console.warn("Environment not recognized for aijobs.");
          }
          break;

        default:
          console.error("Subdomain not recognized.");

      }
    };

    setDomain();
  }, [DomainName, dispatch]);

  return routes.length > 0 ? (
    <AppRouter routes={routes} />
  ) : (
    <div>Loading...</div>
  );

  return <AppRouter />;
}

export default App;